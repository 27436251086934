// fb.ts
//  引入登录SDK
export const fbJsdk = (typeIndex) => {
  ;(function (d: any, s: any, id: any) {
    var js
    var fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    ;(js as any).src = 'https://connect.facebook.net/zh_CN/sdk.js'
    ;(fjs as any).parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
  // 初始化
  ;(window as any).fbAsyncInit = function () {
    // @ts-ignore：
    // eslint-disable-next-line no-undef
    FB.init({
      appId: '990922070918466', // 应用编号
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v16.0',
    })
    if (typeIndex) {
      ShareFacebook()
    }
  }
}

declare const FB: any
export const ShareFacebook = () => {
  console.log(typeof FB)
  if (typeof FB == 'object') {
    FB.ui(
      {
        // display: 'popup',
        method: 'share',
      },
      function (response) {}
    )
  } else {
    fbJsdk(1)
  }
}
